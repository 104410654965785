html {
  background-color: #fff;
}

.App {
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

@keyframes blinkPink {
  78% {
    color: inherit;
    text-shadow: none;
  }
  79% {
    text-shadow: 0px 0px 10px #ff39b0;
  }
  80% {
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: none;
  }
  82% {
    text-shadow: 0px 0px 10px #ff39b0;
  }
  83% {
    color: inherit;
    text-shadow: none;
  }
  92% {
    text-shadow: 0px 0px 10px #ff39b0;
  }
  92.5% {
    color: inherit;
    text-shadow: none;
  }
}

@keyframes blinkBlue {
  78% {
    color: inherit;
    text-shadow: none;
  }
  79% {
    text-shadow: 0px 0px 10px #61dafb;
  }
  80% {
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: none;
  }
  82% {
    text-shadow: 0px 0px 10px #61dafb;
  }
  83% {
    color: inherit;
    text-shadow: none;
  }
  92% {
    text-shadow: 0px 0px 10px #61dafb;
  }
  92.5% {
    color: inherit;
    text-shadow: none;
  }
}

@keyframes blinkLemon {
  78% {
    color: inherit;
    text-shadow: none;
  }
  79% {
    text-shadow: 0px 0px 10px #f9ff01;
  }
  80% {
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: none;
  }
  82% {
    text-shadow: 0px 0px 10px #f9ff01;
  }
  83% {
    color: inherit;
    text-shadow: none;
  }
  92% {
    text-shadow: 0px 0px 10px #f9ff01;
  }
  92.5% {
    color: inherit;
    text-shadow: none;
  }
}

.blink-pink {
  animation: blinkPink linear infinite 3s;
}

.blink-blue {
  animation: blinkBlue linear infinite 4s;
}

.blink-lemon {
  animation: blinkLemon linear infinite 3s;
}

@keyframes blinkingIcon {
  0% {
    fill: #00ffff;
    filter: drop-shadow(0 0 10px #00ebff);
  }

  33% {
    fill: transparent;
  }

  49% {
    fill: #00ffff;
    filter: drop-shadow(0 0 10px #00ebff);
  }
  60% {
    fill: transparent;
  }
  99% {
    fill: transparent;
  }
  100% {
    fill: #00ffff;
    filter: drop-shadow(0 0 10px #00ebff);
  }
}
.blinking {
  animation: blinkingIcon 1.2s infinite;
}

.button-gradient-aqua {
  background: linear-gradient(
    355deg,
    #f0fe3b 0%,
    #c9fc6e 23.414%,
    #a6fa9d 43%,
    #8cf9bf 70.07%,
    #7cf8d4 88.101%,
    #77f8dc 100%
  );
}
.button-gradient-aqua:focus,
.button-gradient-aqua:hover {
  background: white;
}

.text-gradient-aqua {
  background: linear-gradient(
    200deg,
    #f0fe3b 0%,
    #c9fc6e 23.414%,
    #a6fa9d 43%,
    #8cf9bf 70.07%,
    #7cf8d4 88.101%,
    #77f8dc 100%
  );
}

/* .gradient-border::after {
  background-color: white;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  border-radius: inherit;
} */

.gradient-border::after {
  content: "";
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -10;
  background: white;
  border-radius: 0.7rem;
}

.gradient-border::before {
  content: "";
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -10;
  margin: -2.25px;
  border-radius: inherit;
  background: linear-gradient(
    200deg,
    #f0fe3b 0%,
    #c9fc6e 23.414%,
    #a6fa9d 43%,
    #8cf9bf 70.07%,
    #7cf8d4 88.101%,
    #77f8dc 100%
  );
}

button.gradient-border::after {
  content: "";
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -10;
  background: white;
  border-radius: 0.4rem;
  margin: 2px;
}

button.gradient-border::before {
  content: "";
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -10;
  margin: 0px;
  border-radius: inherit;
  background: linear-gradient(
    200deg,
    #f0fe3b 0%,
    #c9fc6e 23.414%,
    #a6fa9d 43%,
    #8cf9bf 70.07%,
    #7cf8d4 88.101%,
    #77f8dc 100%
  );
}

button.gradient-border:hover::before {
  content: "";
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -10;

  border-radius: inherit;
  background: #70e4cb;
}

.gradient-aqua {
  background: linear-gradient(
    -18deg,
    #f0fe3b -10%,
    #c9fc6e 23.414%,
    #a6fa9d 43%,
    #8cf9bf 70.07%,
    #7cf8d4 88.101%,
    #77f8dc 100%
  );
}

.animated-gradient-border::after {
  content: "";
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -10;
  margin: 0.3rem;
  background: white;
  border-radius: 0.3rem;
}

.animated-gradient-border::before {
  content: "";
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -10;
  margin: -10rem;
  animation: spin 2s linear infinite;

  border-radius: inherit;
  background: linear-gradient(
    200deg,
    #f0fe3b 0%,
    #c9fc6e 23.414%,
    #a6fa9d 43%,
    #8cf9bf 70.07%,
    #7cf8d4 88.101%,
    #77f8dc 100%
  );
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
